"use strict";

var _interopRequireDefault = require("/Users/wangrq/Documents/Idea/WebProject/andpods-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _store = _interopRequireDefault(require("@/store"));

var _default = {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var roles = _store.default.getters && _store.default.getters.roles;

    if (value && value instanceof Array) {
      if (value.length > 0) {
        var permissionRoles = value;
        var hasPermission = roles.some(function (role) {
          return permissionRoles.includes(role);
        });

        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    } else {
      throw new Error("\u4F7F\u7528\u65B9\u5F0F\uFF1A v-permission=\"['admin','editor']\"");
    }
  }
};
exports.default = _default;